import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '~/utils/tw';

const labelVariants = cva(
  'text-sm leading-none peer-disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        default: 'text-homy-gray-light',
        checkbox: 'text-homy-gray-darker',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface LabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement>,
    VariantProps<typeof labelVariants> {
  asChild?: boolean;
  disabled?: boolean;
}

const Label = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({ className, variant, disabled, ...props }, ref) => {
    return (
      <LabelPrimitive.Root
        ref={ref}
        className={cn(labelVariants({ variant, className }), {
          'cursor-not-allowed text-homy-gray-light': disabled,
        })}
        {...props}
      />
    );
  },
);

Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
